
interface IProps {
    title: string,
    children: JSX.Element
}
export const InputSection = ({ title, children }: IProps) => {

    return (
        <div className={"my-2"}>
            <span className={"flex align-self-start text-lg text-dark-600 dark:text-light-400"}>{title}</span>
            {children}
        </div>
    )
}