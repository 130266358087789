import { configureStore } from "@reduxjs/toolkit"
import imageReducer from "./Image-Slice"
import settingsReducer from "./Settings-Slice"

export const store = configureStore({
    reducer: {
        setting: settingsReducer,
        image: imageReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;