
interface IProps {
    title: string;
    text: string;
}

export const ContentRow = ({ title, text }: IProps) => {

    return (
        <div className={"flex flex-col min-h-1/6 justify-center py-4 bg-light-500 dark:bg-dark-500"}>
            <p className={"mb-2 capitalize text-3xl tracking-wider text-dark-600 dark:text-light-500"}>{title}</p>
            <p className={"text-xl text-dark-500 dark:text-light-300"}>{text}</p>
        </div>
    );
}