import { FocusEventHandler, useState } from "react";
import { toast } from 'react-toastify';

interface IProps {
    label: string,
    minimum: number,
    maximum: number,
    value: number,
    onBlurHandler?: FocusEventHandler<HTMLInputElement>,
}

export const ConstrainedNumberInput = ({ label, minimum, maximum, value, onBlurHandler }: IProps) => {
    const [dirtyValue, _setDirtyValue] = useState(value);
    const [isDirty, setDirty] = useState(false);

    const setDirtyValue = (v: number) => {
        setDirty(true);
        _setDirtyValue(v);
    }

    const trueValue = isDirty ? dirtyValue : value;

    return (
        <div className={"flex flex-col"}>
            <input value={trueValue} min={minimum} max={maximum} type={"number"}
                className={"block form-control w-20 h-8 text-center font-normal rounded transition ease-in-out bg-clip-padding border border-solid text-dark-600 bg-light-500 dark:text-light-500 dark:bg-dark-500 border-gray-300 dark:border-dark-300 focus:border-blue-600 focus:outline-none"}
                onBlur={(e) => {
                    //If input invalid
                    if (!e.target.checkValidity() || e.target.value === "") {
                        let badValue = +e.target.value;

                        //Less than minimum: set to minimum and show toast
                        if (badValue < +e.target.min || badValue > +e.target.max) {
                            toast.error(`Value must be between ${e.target.min} - ${(+e.target.max).toFixed(2)}`);
                            e.target.value = value.toString();
                        }
                    }
                    if (onBlurHandler) onBlurHandler(e);
                    setDirty(false);
                }}
                onChange={(e) => setDirtyValue(+e.target.value)}
            />
            <span className={"self-end pr-1 text-sm text-dark-100 dark:text-light-100"}>{label}</span>
        </div>
    );
}