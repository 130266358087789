import { switchUnitType, } from "../../../stores/Settings-Slice";
import { useAppDispatch, useAppSelector } from '../../../stores/Hooks';

export const UnitTypeToggle = () => {
    const settings = useAppSelector((s) => s.setting);
    const dispatch = useAppDispatch();

    return (
        <div className={"flex"}>
            <button data-unit={"in"} data-active={settings.unitType === "in"}
                className={"h-10 w-28 self-end px-4 rounded-l-md bg-light-100 data-[active=true]:bg-secondary-600 hover:data-[active=false]:bg-secondary-300"}
                onClick={() => dispatch(switchUnitType())}>
                <span className={"font-bold tracking-wider text-light-500"}>Inches</span>
            </button>
            <button data-unit={"in"} data-active={settings.unitType === "mm"}
                className={"h-10 w-32 self-end px-4 rounded-r-md bg-light-100 data-[active=true]:bg-secondary-600 hover:data-[active=false]:bg-secondary-300"}
                onClick={() => dispatch(switchUnitType())}>
                <span className={"font-bold tracking-widest text-light-500"}>Millimeters</span>
            </button>
        </div>);
}