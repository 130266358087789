import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INCH_TO_MM_CONVERSION = 25.4;

const inDimensionConstraints = {
    sideMax: 60,
    sideMin: 6,
    depthMax: 6,
    depthMin: 1
}

const mmDimensionConstraints = {
    sideMax: inDimensionConstraints.sideMax * INCH_TO_MM_CONVERSION,
    sideMin: inDimensionConstraints.sideMin * INCH_TO_MM_CONVERSION,
    depthMax: inDimensionConstraints.depthMax * INCH_TO_MM_CONVERSION,
    depthMin: inDimensionConstraints.depthMin * INCH_TO_MM_CONVERSION
}

export const inDepthOffConstraints = {
    min: 0.25,
    max: 1
}
export const mmDepthOffConstraints = {
    min: inDepthOffConstraints.min * INCH_TO_MM_CONVERSION,
    max: inDepthOffConstraints.max * INCH_TO_MM_CONVERSION,
}

interface State {
    unitType: "in" | "mm";
    isUpdateDisabled: boolean;
    isProcessing: boolean;
    boardWidth: number;
    boardHeight: number;
    boardDepth: number;
    isDimensionsUpdated: boolean;
    nailHeadDiameter: number;
    drillDepth: number;
    nailDensity: number;
    nailRandomness: number;
    nailCount: number;
    offset: number;
    type: "Simple" | "Peck Cycles"
    dimensionConstraints: typeof inDimensionConstraints | typeof mmDimensionConstraints;
    depthOffConstraints: typeof inDepthOffConstraints | typeof mmDepthOffConstraints;
}

export const initialState: State = {
    unitType: "in",
    isUpdateDisabled: true,
    isProcessing: false,
    boardWidth: 12,
    boardHeight: 12,
    boardDepth: 1,
    isDimensionsUpdated: false,
    nailHeadDiameter: 8,
    drillDepth: 0.5,
    nailDensity: 10,
    nailRandomness: 0,
    nailCount: 0,
    offset: 0.5,
    type: "Peck Cycles",
    dimensionConstraints: inDimensionConstraints,
    depthOffConstraints: inDepthOffConstraints,
};

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        //Switch Unit Type (in->mm, mm->in)
        switchUnitType(state) {
            if (state.unitType === "mm") {
                state.boardHeight = Number((state.boardHeight / INCH_TO_MM_CONVERSION).toFixed(2));
                state.boardWidth = Number((state.boardWidth / INCH_TO_MM_CONVERSION).toFixed(2));
                state.boardDepth = Number((state.boardDepth / INCH_TO_MM_CONVERSION).toFixed(2));
                state.drillDepth = Number((state.drillDepth / INCH_TO_MM_CONVERSION).toFixed(2));
                state.offset = Number((state.offset / INCH_TO_MM_CONVERSION).toFixed(2));

                state.unitType = "in";
                state.dimensionConstraints = inDimensionConstraints;
            } else if (state.unitType === "in") {
                state.boardHeight = Number((state.boardHeight * INCH_TO_MM_CONVERSION).toFixed(2));
                state.boardWidth = Number((state.boardWidth * INCH_TO_MM_CONVERSION).toFixed(2));
                state.boardDepth = Number((state.boardDepth * INCH_TO_MM_CONVERSION).toFixed(2));
                state.drillDepth = Number((state.drillDepth * INCH_TO_MM_CONVERSION).toFixed(2));
                state.offset = Number((state.offset * INCH_TO_MM_CONVERSION).toFixed(2));

                state.unitType = "mm";
                state.dimensionConstraints = mmDimensionConstraints;
            }
        },
        // Switch the Min/Maxs of the Text elements
        switchDimensionConstraints(state) {
            state.dimensionConstraints = state.unitType === "mm" ? mmDimensionConstraints : inDimensionConstraints;
            state.depthOffConstraints = state.unitType === "mm" ? mmDepthOffConstraints : inDepthOffConstraints;
        },
        //Toggle Disabled State (T->F, F->T)
        toggleIsUpdateDisabled(state) {
            state.isUpdateDisabled = !state.isUpdateDisabled;
        },
        //Toggle Processing State (T->F, F->T)
        toggleIsProcessing(state) {
            state.isProcessing = !state.isProcessing;
        },
        //Toggle Nail Randomness in Image
        updateNailRandomness(state, action: PayloadAction<number>) {
            state.nailRandomness = action.payload;
        },
        //Toggle Board Updated State (T->F, F->T)
        toggleIsDimensionsUpdated(state) {
            state.isDimensionsUpdated = !state.isDimensionsUpdated;
        },
        //Update Board Width
        updateWidth(state, action: PayloadAction<number>) {
            state.boardWidth = Number((action.payload).toFixed(2));
        },
        //Update Board Height
        updateHeight(state, action: PayloadAction<number>) {
            state.boardHeight = Number((action.payload).toFixed(2));
        },
        //Update BoardDepth
        updateDepth(state, action: PayloadAction<number>) {
            state.boardDepth = Number((action.payload).toFixed(2));
        },
        //Update Spin Rate
        updateDrillDepth(state, action: PayloadAction<number>) {
            state.drillDepth = action.payload;
        },
        //Update Nail Diam
        updateNailDiameter(state, action: PayloadAction<number>) {
            state.nailHeadDiameter = action.payload;
        },
        //Update Density
        updateNailDensity(state, action: PayloadAction<number>) {
            state.nailDensity = action.payload;
        },
        //Update Nail Count
        updateNailCount(state, action: PayloadAction<number>) {
            state.nailCount = action.payload;
        },
        //Update Offset
        updateOffset(state, action: PayloadAction<number>) {
            state.offset = action.payload;
        },
        //Switch GCode Type (Peck -> Simple & Simple -> Peck)
        switchType(state) {
            state.type = state.type === "Peck Cycles" ? "Simple" : "Peck Cycles";
        },
    }
})

export const { switchUnitType, toggleIsUpdateDisabled, toggleIsProcessing, updateWidth, updateHeight, updateDepth, toggleIsDimensionsUpdated, updateNailDiameter, updateDrillDepth, updateNailDensity, updateNailRandomness, updateNailCount, updateOffset, switchDimensionConstraints, switchType } = settingSlice.actions;
export default settingSlice.reducer;