import init, { dither, get_nails, to_gcode, re_size, erode, get_image_dimensions, get_overlay } from "wasm-lib";

/**
 * Dithers the image by calling the Rust `dither` method
 * @param image The original image to be translated into a dithered image
 * @returns The dithered image
 */
export const translate = async (image: string) => {
  return await init().then(async () => {
    let bytes = await to_bytes(image)

    let dithered: Uint8Array = dither(bytes)

    let mime_type = getMime(dithered)
    if (mime_type === undefined) { return Promise.reject(new Error("Conversion to dithered image failed")) }

    return Promise.resolve(new Blob([dithered], { type: mime_type }))
  })
}

/**
 * Resizes an image based on the specified width and height by calling the Rust `resize` method
 * @param image The original image to be resized
 * @param n_width The desired new width of the image
 * @param n_height The desired new height of the image
 * @param offset The desired offset from the edge of the board. This will need to be multiplied by 2 to account for both sides
 * @param nailDiam The diamater of the nail being used. This is used to help space the image
 * @returns The resized image
 */
export const resize = async (image: string, n_width: number, n_height: number, offset: number, nailDiam: number): Promise<Blob> => {
  return await init().then(async () => {
    let bytes = await to_bytes(image);

    let w = (n_width - (offset * 2)) * nailDiam;
    let h = (n_height - (offset * 2)) * nailDiam;

    let scaled: Uint8Array = re_size(bytes, w, h);

    let mime_type = getMime(bytes);
    if (mime_type === undefined) { return Promise.reject(new Error("Can not resize provided image")) }

    return Promise.resolve(new Blob([scaled], { type: mime_type }));
  })
}

/**
 * Reduces the density of the nails through a custom erosion algorithm by calling the Rust `erode` method
 * @param image The dithered image
 * @param density An integer between 5 and 10 representing the relative density of the nails
 * @param randomness An integer between 1 and 10 representing the random chance that a removed nail will be added back into the image
 * @returns A new dithered image
 */
export const eroder = async (image: string, density: number, randomness: number) => {
  return await init().then(async () => {
    let bytes = await to_bytes(image)

    let eroded: Uint8Array = erode(bytes, density, randomness)

    let mime_type = getMime(eroded)
    if (mime_type === undefined) { return Promise.reject(new Error("Could not erode image")) }

    return Promise.resolve(new Blob([eroded], { type: mime_type }));
  })
}

/**
 * Counts the number of pixels in the dithered image to determine the number of nails by calling the Rust `get_nails` method
 * @param image The dithered image
 * @returns 
 */
export const getNails = async (image: string): Promise<number> => {
  return await init().then(async () => {
    let bytestring = await to_bytes(image)
    return get_nails(bytestring)
  })
}

/**
 * Gets the paper overlay based on the dithered image by calling the Rust `get_overlay` method
 * @param image The dithered image
 * @returns A boolean array of nails in the dithered image
 */
export const getOverlayArray = async (image: string) => {
  return await init().then(async () => {
    let bytestring = await to_bytes(image)

    return get_overlay(bytestring)
  })
}

/**
 * Calculates the dimensions of the image by calling the Rust `get_image_dimensions` method
 * @param image The dithered image
 * @returns A tuple for [width, height]
 */
export const getImageDimensions = async (image: string) => {
  return await init().then(async () => {
    let bytestring = await to_bytes(image)

    return get_image_dimensions(bytestring);
  })
}

/**
 * Transforms the dithered image into a GCode file that will have a CNC machine drill pilot holes for every nail in the image
 * @param dithered The dithered iamge
 * @param gcode_type 'Simple' GCode is for at-home DIY CNC Machines while 'Peck Cycles' is for industrial type CNC Machines like the one used in MSOE's Science Building
 * @param unit_type The current unit type, 'in' or 'mm'
 * @param nailDiam The diameter of the desired nail head
 * @param name The name of the file to download
 * @param depth The depth at which to drill into the board
 * @param offset The safety margins at the edge of the board to avoid drilling into any mounting/clamping equipment
 * @returns The string of the GCode to download in a file
 */
export const downloadGCode = async (dithered: string, gcode_type: "Simple" | "Peck Cycles", unit_type: "in" | "mm", nailDiam: number, name: string, depth: number, offset: number) => {
  return await init().then(async () => {
    const bytestring = await to_bytes(dithered)
    const gcode: string = await to_gcode(bytestring, gcode_type, unit_type, nailDiam, name, -1 * depth, offset)

    //TODO better error message
    if (!gcode) return Promise.reject(new Error("Could not find image"));
    return Promise.resolve(gcode);
  })
}

/**
 * Converts an image string into a Uint8Array to send to Rust
 * @param image Any image string
 * @returns The Uint8Array of the image to send to Rust
 */
const to_bytes = async (image: string) => {
  return await fetch(image).then(res =>
    res.blob()
  ).then(bob =>
    bob.arrayBuffer()
  ).then(buf =>
    new Uint8Array(buf)
  )
}

/**
 * Determines the images mime type based on the header in the image
 * @param buf The array buffer of the image
 * @returns The mime type of the image (e.g. 'image/png', 'image/jpeg')
 */
const getMime = (buf: ArrayBuffer) => {
  /*
  Thanks, Drakes:
  https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
  */
  var arr = (new Uint8Array(buf)).subarray(0, 4)
  var header = ""
  for (var i = 0; i < arr.length; i++) {
    header += arr[i].toString(16)
  }

  switch (header) {
    case "89504e47":
      return "image/png"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"
    default:
      return undefined
  }
}