import { useAppSelector } from '../../stores/Hooks';
import { ToolDrawer } from "./sidebar/ToolDrawer";

export const MaterialsTracker = () => {
  const settings = useAppSelector((s) => s.setting);

  const pluralize = (noun: string, amount: number) => {
    return `${noun}${amount === 1 ? "" : "s"}`;
  }

  return (
    <ToolDrawer title={"Materials Tracker"}>
      <p className={"flex justify-self-start m-0 text-dark-600 dark:text-light-400"} >{settings.nailCount} - {pluralize(`1/${settings.nailHeadDiameter} Inch Head Nail`, settings.nailCount)}</p>
      <p className={"flex justify-self-start m-0 text-dark-600 dark:text-light-400"} >{1} - {`${settings.boardWidth}x${settings.boardHeight}x${settings.boardDepth} ${settings.unitType} Board`}</p>
      <p className={"flex justify-self-start m-0 text-dark-600 dark:text-light-400"} >{1} - {settings.nailHeadDiameter < 8 ? 'Really Really Big Hammer' : 'Big Hammer'}</p>
    </ToolDrawer>

  );
}