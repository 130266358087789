import { ContentRow } from "./ContentRow";
import { ImageRow } from "./ImageRow";
interface IProps {
    backgroundImage: string;
    title: string;
    text: string;
    textOverlay?: string;
}

export const ProjectProgressionRow = ({ backgroundImage, title, text, textOverlay }: IProps) => {

    return (
        <div className={"h-screen w-full"}>
            <ContentRow title={title} text={text} />
            <ImageRow image={backgroundImage} textOverlay={textOverlay} />
        </div>
    );
}