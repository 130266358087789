import { useState } from 'react';
import { toast } from 'react-toastify'

import StraightenIcon from '@mui/icons-material/Straighten';
import PaletteIcon from '@mui/icons-material/Palette';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import { updateNailCount, toggleIsProcessing, toggleIsUpdateDisabled } from "../../../stores/Settings-Slice";
import { updateDithered, updateSized } from "../../../stores/Image-Slice";
import { useAppDispatch, useAppSelector } from '../../../stores/Hooks';

import { translate, getNails, resize, downloadGCode, eroder } from '../../../services/ImageTranslation'
import { createOverlay } from '../../../services/PaperOverlay'
import { ToolMenuButton } from './ToolMenuButton';
import { ArtSettings } from './ArtSettings';
import { BoardSettings } from "./BoardSettings";
import { GCodeSettings } from './GCodeSettings';
// import { Spinner } from "@blueprintjs/core";
import { MaterialsTracker } from "../MaterialsTracker";
import { updateEroded } from "../../../stores/Image-Slice";

interface IMenuToggleState {
    Dimensions: boolean,
    ArtSettings: boolean,
    CncSettings: boolean,
    Materials: boolean,
}

const closedMenuToggleState = {
    Dimensions: false,
    ArtSettings: false,
    CncSettings: false,
    Materials: false
}

export const SideBar = () => {
    const settings = useAppSelector((s) => s.setting);
    const images = useAppSelector((s) => s.image);
    const dispatch = useAppDispatch();
    const [menuState, setMenuState] = useState<IMenuToggleState>(closedMenuToggleState);
    const isMenuOpen = menuState.Dimensions || menuState.ArtSettings || menuState.CncSettings || menuState.Materials;

    const currentTool = () => {
        if (menuState.Dimensions) {
            return <BoardSettings />
        } else if (menuState.ArtSettings) {
            return <ArtSettings />
        } else if (menuState.CncSettings) {
            return <GCodeSettings />
        } else if (menuState.Materials) {
            return <MaterialsTracker />
        }
    }

    const toggleProcessingAndDisabled = () => {
        dispatch(toggleIsProcessing());
        dispatch(toggleIsUpdateDisabled());
    }

    //TODO unduplicate this with DitheredImageDisplay
    const reImage = () => {
        toggleProcessingAndDisabled();

        const image = images.originalImage;

        if (!image) {
            toast.error("Could not find image");
            return;
        }

        resize(image, settings.boardWidth, settings.boardHeight, settings.offset, settings.nailHeadDiameter)
            .then(scaledBlob => {
                let scaledImage = URL.createObjectURL(scaledBlob);
                dispatch(updateSized(scaledImage));
                return scaledImage;
            })
            .then(scaledUrl => translate(scaledUrl))
            .then(ditheredBlob => {
                let ditheredImage = URL.createObjectURL(ditheredBlob);
                dispatch(updateDithered(ditheredImage));
                return ditheredImage;
            })
            .then(ditheredUrl => eroder(ditheredUrl, settings.nailDensity, settings.nailRandomness))
            .then(erodedBlob => {
                let erodedImage = URL.createObjectURL(erodedBlob);
                dispatch(updateEroded(erodedImage));
                return erodedImage;
            })
            .then(erodedUrl => getNails(erodedUrl))
            .then(nails => dispatch(updateNailCount(nails)))
            .catch(e => toast.error(e.message))
            .finally(() => {
                toggleProcessingAndDisabled();
            });
    }

    const downloadPaper = async () => {
        toggleProcessingAndDisabled();

        const ditheredImage = (images.erodedImage !== "" ? images.erodedImage : images.ditheredImage);

        if (!ditheredImage) {
            toast.error("No image was found");
            toggleProcessingAndDisabled();
            return;
        }

        const element = await createOverlay(ditheredImage, settings.boardHeight, settings.boardWidth, settings.nailHeadDiameter, settings.unitType, settings.offset);
        document.body.appendChild(element);
        element.click();

        toggleProcessingAndDisabled();
    }

    const downloadGcode = async () => {
        toggleProcessingAndDisabled();

        const ditheredImage = (images.erodedImage !== "" ? images.erodedImage : images.ditheredImage);
        const name = (Math.floor(Math.random() * 9001) + 1000).toString();

        if (!ditheredImage) {
            toast.error("No image was found");
            toggleProcessingAndDisabled();
            return;
        }

        // let gcode = await downloadGCode(ditheredImage, settings.boardDepth, settings.unitType, settings.nailHeadDiameter)
        downloadGCode(ditheredImage, settings.type, settings.unitType, settings.nailHeadDiameter, name, settings.drillDepth, settings.offset)
            .then(gCode => {
                if (!gCode) {
                    throw Error("Conversion to GCode failed");
                }
                const blob = new Blob([gCode], { type: 'text/plain; charset=utf-8' });
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = name + ".nc";
                document.body.appendChild(element);
                element.click();
            })
            .catch(e => toast.error(e.message))
            .finally(() => {
                toggleProcessingAndDisabled();
            });
    }

    const closeToolMenu = () => {
        setMenuState(closedMenuToggleState);
    }

    const muiIconColor = '#A5534A';
    const iconBar = (
        <div className={"flex flex-col place-content-between w-32 bg-light-500 dark:bg-dark-500"} >
            <div>
                <ToolMenuButton
                    label={"Dimensions"}
                    isOpen={menuState.Dimensions}
                    icon={<StraightenIcon fontSize={"large"} sx={{ color: muiIconColor }} />}
                    onClick={() => setMenuState({ ...closedMenuToggleState, Dimensions: !menuState.Dimensions })} />

                <ToolMenuButton
                    label={"Art Settings"}
                    isOpen={menuState.ArtSettings}
                    icon={<PaletteIcon fontSize={"large"} sx={{ color: muiIconColor }} />}
                    onClick={() => setMenuState({ ...closedMenuToggleState, ArtSettings: !menuState.ArtSettings })} />

                <ToolMenuButton
                    label={"CNC Settings"}
                    isOpen={menuState.CncSettings}
                    icon={<PrecisionManufacturingIcon fontSize={"large"} sx={{ color: muiIconColor }} />}
                    onClick={() => setMenuState({ ...closedMenuToggleState, CncSettings: !menuState.CncSettings })} />
                <ToolMenuButton
                    label={"Materials Tracker"}
                    isOpen={menuState.Materials}
                    icon={<ArtTrackIcon fontSize={"large"} sx={{ color: muiIconColor }} />}
                    onClick={() => setMenuState({ ...closedMenuToggleState, Materials: !menuState.Materials })} />
            </div>
            <div>
                <ToolMenuButton label={"Paper Overlay"} icon={<SaveAltIcon fontSize={"large"} sx={{ color: muiIconColor }} />} isOpen={false} onClick={downloadPaper} />
                <ToolMenuButton label={"G-Code File"} icon={<AssignmentReturnedIcon fontSize={"large"} sx={{ color: muiIconColor }} />} isOpen={false} onClick={downloadGcode} />
            </div>
        </div>
    );

    const updateButton = (
        <button data-visible={!menuState.Materials} disabled={settings.isUpdateDisabled} className={"h-10 w-28 self-end px-4 mr-4 rounded-md bg-secondary-600 disabled:bg-light-100 enabled:hover:bg-secondary-500 data-[visible=false]:hidden"} onClick={reImage}>
            <span data-processing={settings.isProcessing} className={"font-bold tracking-wider text-light-500 data-[processing=true]:hidden"}>Update</span>
            <div data-processing={settings.isProcessing} className={"hidden justify-center data-[processing=true]:flex"}>
                {/* <Spinner size={25} /> */}
            </div>
        </button>
    );

    //TODO
    const closeMenuButton = (
        <button className={""} onClick={closeToolMenu}>

        </button>
    );

    const toolBar = (
        <div data-open={isMenuOpen} className={"hidden data-[open=true]:flex flex-col bg-light-600 dark:bg-dark-400"}>
            {currentTool()}
            {updateButton}
        </div>
    );

    return (
        <section className={"flex h-full float-left z-50"}>
            {iconBar}
            {toolBar}
        </section>
    );
}