import { MouseEventHandler } from "react";

interface IProps {
    label: string;
    isOpen: boolean;
    icon: JSX.Element;
    onClick: MouseEventHandler;
}

export const ToolMenuButton = ({ label, isOpen, icon, onClick }: IProps) => {
    return (
        <button data-open={isOpen} className={"flex flex-col box-border w-full items-center py-8 px-4 hover:bg-light-400 dark:hover:bg-dark-300 data-[open=true]:bg-light-600 dark:data-[open=true]:bg-dark-400"} onClick={onClick}>
            {icon}
            <p className={"text-md font-semibold select-none text-dark-600 dark:text-light-300"}>{label}</p>
        </button>
    )
}