import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { initialState, INCH_TO_MM_CONVERSION, updateWidth, updateHeight, updateDepth, updateNailDiameter, updateDrillDepth } from "../../../stores/Settings-Slice";
import { useAppDispatch, useAppSelector } from '../../../stores/Hooks';

import { ToolDrawer } from "./ToolDrawer";
import { UnitTypeToggle } from './UnitTypeToggle';
import { InputSection } from "../../common/InputSection";
import { ConstrainedNumberInput } from "../../common/ConstrainedNumberInput";
import { toast } from 'react-toastify';

export const BoardSettings = () => {
    const settings = useAppSelector((s) => s.setting);
    const dispatch = useAppDispatch();

    const onNailChange = (event: SelectChangeEvent) => {
        dispatch(updateNailDiameter(+event.target.value));
    };

    const boardDimensionInput = (
        <div className={"flex xl:w-96 space-x-6"}>
            <ConstrainedNumberInput label={"height"} minimum={settings.dimensionConstraints.sideMin} maximum={settings.dimensionConstraints.sideMax} value={settings.boardHeight} onBlurHandler={(e) => dispatch(updateHeight(+e.target.value))} />
            <ConstrainedNumberInput label={"width"} minimum={settings.dimensionConstraints.sideMin} maximum={settings.dimensionConstraints.sideMax} value={settings.boardWidth} onBlurHandler={(e) => dispatch(updateWidth(+e.target.value))} />
            <ConstrainedNumberInput label={"depth"} minimum={settings.dimensionConstraints.depthMin} maximum={settings.dimensionConstraints.depthMax} value={settings.boardDepth}
                onBlurHandler={
                    (e) => {
                        let val = +e.target.value
                        if (val !== settings.boardDepth) {
                            toast.info("Due to the board depth changing, the CNC Drill Depth was reset to its default.")
                            dispatch(updateDrillDepth(initialState.drillDepth * (settings.unitType === "in" ? 1 : INCH_TO_MM_CONVERSION)))
                            dispatch(updateDepth(val))
                        }
                    }
                } />
        </div>
    );

    //Material UI does not like Tailwind, so manually determine light/dark mode for input text color
    const muiTextColor = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? '#F2F2F2' : '#2C2C2C';
    const nailDiameterInput = (
        <div className={"bg-light-500 dark:bg-dark-500"}>
            <FormControl fullWidth>
                <Select className={"h-10"} sx={{ color: muiTextColor }} color={"primary"} value={settings.nailHeadDiameter.toString()} onChange={onNailChange}>
                    <MenuItem value={10}>1/16 Inch Head</MenuItem>
                    <MenuItem value={8}>1/8 Inch Head</MenuItem>
                    <MenuItem value={6}>1/6 Inch Head</MenuItem>
                    <MenuItem value={4}>1/4 Inch Head</MenuItem>
                </Select>
            </FormControl>
        </div>
    );

    return (
        <ToolDrawer title={"Board Settings"}>
            <InputSection title={"Units"}><UnitTypeToggle /></InputSection>
            <InputSection title={`Board Size (${settings.unitType})`}>{boardDimensionInput}</InputSection>
            <InputSection title={`Nail Size`}>{nailDiameterInput}</InputSection>
        </ToolDrawer>
    );
}