import IconButton from '../common/IconLinkButton';
import FooterColumn from './FooterColumn';
import { LinkedInIcon } from '../../icons/linked-in-icon';


export const Footer = () => {

    return (
        <div className={"absolute min-h-fit w-full py-4 text-left bg-light-300 dark:bg-dark-500 shadow-inner"}>
            <div className={"flex justify-center"}>
                <div className={"flex space-x-64"}>
                    <FooterColumn header={"Project Sponsors"} >
                        <li className={"text-lg text-dark-500 dark:text-light-400"}>Our project's sponsors and mentors</li>
                        <li>Dr. Chris Taylor</li>
                        <li>Angela Wiedenhoeft</li>
                    </FooterColumn>
                    <FooterColumn header={"Project Supporters"} >
                        <li className={"text-lg text-dark-500 dark:text-light-400"}>A special thanks for their help</li>
                        <li>Justin Sommers</li>
                        <li>Dr. Nathan Patterson</li>
                    </FooterColumn>
                    <FooterColumn header={"Project Developers"} >
                        <li className={"text-lg text-dark-500 dark:text-light-400"}>The Knights Who Say Nail</li>
                        <li><IconButton icon={<LinkedInIcon className={"stroke-blue-500"} />} link={"https://www.linkedin.com/in/aboley"} label={"Austin Boley"} /></li>
                        <li><IconButton icon={<LinkedInIcon className={"stroke-blue-500"} />} link={"https://www.linkedin.com/in/courbin-kettenhofen-12719625a/"} label={"Courbin Kettenhofen"} /></li>
                        <li><IconButton icon={<LinkedInIcon className={"stroke-blue-500"} />} link={"https://www.linkedin.com/in/patrick-hayes-30a6241aa/"} label={"Patrick Hayes"} /></li>
                        <li><IconButton icon={<LinkedInIcon className={"stroke-blue-500"} />} link={"https://www.linkedin.com/in/ryanalexanderrr"} label={"Ryan Alexander"} /></li>
                        <li><IconButton icon={<LinkedInIcon className={"stroke-blue-500"} />} link={"https://www.linkedin.com/in/sam-r-kluck"} label={"Sam Kluck"} /></li>
                    </FooterColumn>
                </div>
            </div>
        </div>
    );
}

export default Footer;