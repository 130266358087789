
interface IProps {
    icon: JSX.Element;
    link?: string;
    label?: string;
}

export const IconLinkButton = ({ icon, link, label }: IProps) => {

    return (
        <div className={"flex justify-center items-center space-x-3"}>
            <a href={link} aria-label={`${label}'s LinkedIn`} >
                <button aria-label={`${label}'s LinkedIn`} >{icon}</button>
            </a>
            <label>{label}</label>
        </div>
    );
}

export default IconLinkButton;