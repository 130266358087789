import React, { DragEvent } from 'react';
import { toast } from 'react-toastify'
import { updateNailCount, toggleIsProcessing, toggleIsUpdateDisabled } from "../../stores/Settings-Slice";
import { clearImages, updateDithered, updateOriginal, updateSized, ACCEPTED_FILE_TYPES, updateEroded } from "../../stores/Image-Slice";
import { useAppDispatch, useAppSelector } from '../../stores/Hooks';

import { ImageDisplay } from '../common/ImageDisplay';
import { translate, getNails, resize, eroder } from '../../services/ImageTranslation'
import { UploadImageIcon } from '../../icons/image-upload';
import { XIcon } from "../../icons/x-ixon";

export const DitheredImageDisplay = () => {
  const settings = useAppSelector((s) => s.setting);
  const images = useAppSelector((s) => s.image);
  const dispatch = useAppDispatch();

  const setImage = async (image: string) => {
    dispatch(updateOriginal(image));
    dispatch(toggleIsProcessing());

    resize(image, settings.boardWidth, settings.boardHeight, settings.offset, settings.nailHeadDiameter)
      .then(scaledBlob => {
        let scaledImage = URL.createObjectURL(scaledBlob);
        dispatch(updateSized(scaledImage));
        return scaledImage;
      })
      .then(scaledUrl => translate(scaledUrl))
      .then(ditheredBlob => {
        let ditheredImage = URL.createObjectURL(ditheredBlob);
        dispatch(updateDithered(ditheredImage));
        return ditheredImage;
      })
      .then(ditheredUrl => eroder(ditheredUrl, settings.nailDensity, settings.nailRandomness))
      .then(erodedBlob => {
        let erodedImage = URL.createObjectURL(erodedBlob);
        dispatch(updateEroded(erodedImage));
        return erodedImage;
      })
      .then(erodedUrl => getNails(erodedUrl))
      .then(nails => dispatch(updateNailCount(nails)))
      .catch(e => toast.error(e.message))
      .finally(() => {
        dispatch(toggleIsProcessing())
        dispatch(toggleIsUpdateDisabled())
      });
  }

  const clearImage = () => {
    dispatch(clearImages());
    dispatch(updateNailCount(0));
    dispatch(toggleIsUpdateDisabled());
  }

  const onImageChange = (file: File) => {
    if (checkFileType(file)) {
      setImage(URL.createObjectURL(file));
    } else {
      toast.error("Unsupported file type");
    }
  }

  const checkFileType = (file: File) => {
    let extension = '.' + file.name.split('.')[1];
    return ACCEPTED_FILE_TYPES.find((el) => el === extension);
  }

  const onImageUpload = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      onImageChange(event.currentTarget.files[0]);
    }
  }

  const onDropFile = (event: DragEvent) => {
    //Prevent default file open behavior
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      onImageChange(event.dataTransfer.files[0]);
    }
  }

  const fileTypes = (
    <div className={"flex self-center space-x-2"}>
      {
        ACCEPTED_FILE_TYPES.map((ft, i) => {
          return <p key={i} className={"text-xs text-gray-500"}>{ft}</p>
        })
      }
    </div>
  );

  const placeholderDisplay = (
    <div className={"flex h-full w-full min-h-fit min-w-fit items-center place-content-center whitespace-nowrap"} onDrop={onDropFile} onDragOver={(e) => e.preventDefault()}>
      <div className={"flex flex-col rounded-xl overflow-hidden hover:drop-shadow-lg bg-light-600 border-2 border-dashed border-dark-200 pt-6 pb-4 px-20"}>
        <span className={"text-3xl font-[550] tracking-wider text-gray-500"}>Knights' Nail Art</span>
        <span className={"text-xl text-gray-500"}>Welcome to our free nail art creator</span>
        <span className={"text-md text-gray-500"}>Start by uploading your own image</span>
        <div className={"flex flex-col space-y-4 mt-4 text-center"}>
          <UploadImageIcon className={"h-20 w-20 self-center stroke-1 stroke-slate-400"} />
          <div className={"flex justify-center text-sm text-gray-600"}>
            <label htmlFor={"file-upload"} className={"relative cursor-pointer rounded-md font-medium text-indigo-600"}>
              <span>Upload an Image</span>
              <input id={"file-upload"} name={"file-upload"} type={"file"} accept={String(ACCEPTED_FILE_TYPES)} className={"sr-only"} onChange={onImageUpload} />
            </label>
            <p className={"pl-1"}>or drag and drop</p>
          </div>
        </div>
        {fileTypes}
      </div>
    </div>
  );

  const removeButton = (
    <button data-visible={images.hasImage} className={"absolute top-2 right-2 h-max w-max rounded-full data-[visible=false]:hidden hover:bg-gray-100 dark:hover:bg-light-100"} onClick={clearImage} >
      <XIcon className={"h-full w-full stroke-1 stroke-dark-500 dark:stroke-mixed-600"} />
    </button>
  );

  return (
    <section className={"flex w-full items-center justify-center select-none"}>
      <figure className={"relative w-3/4 max-w-5/6 min-w-[600px]"}>
        {removeButton}
        <ImageDisplay image={images.erodedImage ? images.erodedImage : images.ditheredImage} placeholder={placeholderDisplay} />
      </figure>
    </section>
  );
}

export default DitheredImageDisplay;