interface IProps {
    header?: string;
    children: JSX.Element[];
}

export const FooterColumn = ({ children, header }: IProps) => {

    return (
        <div className={"flex flex-col w-1/3 min-w-max"}>
            <h1 className={"text-2xl font-semibold text-dark-600 dark:text-light-500"}>{header}</h1>
            <ul className={"flex flex-col items-start space-y-1 text-dark-400 dark:text-light-100"}>
                {children}
            </ul>
        </div>
    );
}

export default FooterColumn;