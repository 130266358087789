import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SideBar } from './sidebar/SideBar';
import { DitheredImageDisplay } from './DitheredImageDisplay';

export const WorkPage = () => {
  return (
    <article className={"flex flex-row flex-1 min-h-full"}>
      <SideBar />
      <DitheredImageDisplay />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
        transition={Slide}
        limit={5}
      />
    </article>
  );
}
