import { ChangeEventHandler } from "react";

interface IProps {
    value: number;
    min: number;
    max: number;
    step: number;
    disabled?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export const Slider = ({ value, min, max, step, disabled, onChange }: IProps) => {
    return (
        <div className={"relative xl:w-72"}>
            <input type={"range"} value={value} min={min} max={max} step={step} disabled={disabled} onChange={onChange} className={"form-range w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"} />
            <span className={"text-sm float-left text-gray-400"}>less</span>
            <span className={"text-sm float-right text-gray-400"}>more</span>
        </div>
    )
}