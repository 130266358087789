
interface IProps {
    image?: string;
    placeholder?: JSX.Element;
}

export const ImageDisplay = ({ image, placeholder }: IProps) => {

    return (
        <div className={"aspect-video rounded-md bg-light-500 dark:bg-dark-100"} >
            {image ? <img className={"h-full w-full object-contain object-center"} src={image} alt={image} /> : (placeholder ?? <></>)}
        </div>
    );
}

export default ImageDisplay;