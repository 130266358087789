import { useState } from 'react';
import './App.css';
import { store } from "./stores/Store";
import { Provider } from "react-redux";

import NavBar from "./components/NavBar";
import { WorkPage } from "./components/workPage/WorkPage";
import { HomePage } from "./components/homePage/HomePage";

function App() {
  const [workPage, setWorkPage] = useState(false);

  return (
    <div className={"App flex flex-col"}>
      <header className={"overflow-x-hidden"}>
        <NavBar workPage={workPage} setWorkPage={setWorkPage} />
      </header>
      <div className={"flex flex-1 bg-light-300 dark:bg-dark-600"}>
        <Provider store={store}>
          {workPage ? <WorkPage /> : <HomePage setWorkPage={setWorkPage} />}
        </Provider>
      </div>
    </div>
  );
}

export default App;