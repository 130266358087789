// File to include all methods for paper overlay
import {getOverlayArray, getImageDimensions} from './ImageTranslation'
import { PDFDocument, grayscale, rgb, StandardFonts } from 'pdf-lib';

// Function to Create a PDF document overlay
// Given a dithered image of width x height size, creates a PDF document of the right amount of pages
//    to cover width x height, where each black pixel from the image will be a corresponding black circle
//    on the PDF of nail head size
export const createOverlay = async (image: string, boardHeight: number, boardWidth: number, nailHeadDiameter: number, unitType: string, buffer: number ): Promise<HTMLAnchorElement> => {
   const overlay_arr = await getOverlayArray(image)

   // PDF-Lib constants created by comparing pixels in an A4 sheet of paper to the standard dimensions (in/mm) of A4
   var PDF_PIXELS_PER_INCH = 72.022430615709;
   var PDF_PIXELS_PER_MM = 2.83465;
   var PAGE_HEIGHT = 11 * PDF_PIXELS_PER_INCH;
   var PAGE_WIDTH = 8.5 * PDF_PIXELS_PER_INCH;

   // Calculate height/width of entire PDF document
   // Note: nail diameter is stored as '8' meaning 1/8" - May 2023
   let pdf_height = boardHeight * PDF_PIXELS_PER_INCH;
   let pdf_width = boardWidth * PDF_PIXELS_PER_INCH;
   let pdf_nail_diam = (1.0/nailHeadDiameter)*PDF_PIXELS_PER_INCH;
   var BUFFER = buffer * PDF_PIXELS_PER_INCH;
   // assumed inches above, if not handle mm here
   if (unitType == "mm") {
       pdf_height = boardHeight * PDF_PIXELS_PER_MM;
       pdf_width = boardWidth * PDF_PIXELS_PER_MM;
       // Currently nailHeadDiameter is only in inches - May 2023
      //  pdf_nail_diam = (1.0/nailHeadDiameter) * PDF_PIXELS_PER_MM;
      var BUFFER = buffer * PDF_PIXELS_PER_MM;
   }

   // Use a minimum of 0.25" buffer to avoid trimming edges, otherwise use CNC setting specified buffer
   var STD_BUFFER = 0.25 * PDF_PIXELS_PER_INCH;
   if(BUFFER < STD_BUFFER) {
      BUFFER = STD_BUFFER;
   } else {
      STD_BUFFER = BUFFER;
   }

   // Calculate dimensions for actual image in nails
   let image_dims = await getImageDimensions(image);
   // image dimension pixels * nail size 
   let image_width = (image_dims[0]);
   let image_height = (image_dims[1]);

   // Calculate amount of pages based on board dimensions and Letter Paper (11" x 8.5")
   let pages_tall = Math.ceil(pdf_height / (PAGE_HEIGHT - STD_BUFFER*2));
   let pages_wide = Math.ceil(pdf_width / (PAGE_WIDTH - STD_BUFFER*2));


   let pg_nails_tall = Math.ceil((PAGE_HEIGHT - STD_BUFFER*2)  / pdf_nail_diam);
   let pg_nails_wide = Math.ceil((PAGE_WIDTH - STD_BUFFER*2) / pdf_nail_diam);

   const pdfDoc = await PDFDocument.create();
   const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

   // for each page in document, create dots where needed
   for (let pageY = 0; pageY < pages_tall; pageY++) {
      for (let pageX = 0; pageX < pages_wide; pageX++) {
         const page = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);

         // Add page index
         page.drawText("Left-Right: " + pageX + ", Top-Bottom: " + pageY
         , {
            x: STD_BUFFER,
            y: PAGE_HEIGHT-STD_BUFFER-25,
            font: helveticaFont,
            size: 24,
            color: rgb(0,0,0),
            lineHeight: 25,
            opacity: 0.75
         },);

         let leftEdge = pageX == 0;
         let topEdge = pageY == 0;

         // iterate over a page by the amount of nails that fit on a page
         // keep in mind the PDF uses pixels, while a "nail" is considered (nail head size) pixels large
         // few printers can print all the way to the edge, so add margins
         //    the CNC buffer will be the top and left -most pages buffer, while a generic 0.25" margin will be used at a minimum for printer safety
         for (let nailY = 0; nailY < pg_nails_tall; nailY++) {
            for (let nailX = 0; nailX < pg_nails_wide; nailX++) {
               let boardX = pageX * pg_nails_wide + nailX;
               let boardY = pageY * pg_nails_tall + nailY;

               if(boardX > image_width) {
                  continue;
               }
               if(boardY > image_height) {
                  continue;
               }

               let imageX = boardX;// - Math.ceil(BUFFER/pdf_nail_diam);
               let imageY = boardY;// - Math.ceil(BUFFER/pdf_nail_diam);
               let imageIndex = imageY * image_width + imageX; // row * width + col
               // If black, draw dot
               let pdfX = nailX * pdf_nail_diam;
               let pdfY = nailY * pdf_nail_diam;
               if(overlay_arr[imageIndex] == 1) {
                  let x_dot_buffer = STD_BUFFER;
                  let y_dot_buffer = STD_BUFFER;
                  if(leftEdge) {
                     x_dot_buffer = BUFFER;
                  }
                  if(topEdge) {
                     y_dot_buffer = BUFFER;
                  }

                  page.drawCircle({
                     x: pdfX + (pdf_nail_diam/2) + x_dot_buffer,
                     y: PAGE_HEIGHT-pdfY - (pdf_nail_diam/2) - y_dot_buffer,
                     size: pdf_nail_diam/2,
                     borderWidth: 1,
                     borderColor: grayscale(1),
                     color: rgb(0, 0, 0),
                     opacity: 1,
                     borderOpacity: 0,
                  })
               }
               // console.log(
               //    "Page X: " + pageX + "\nPageY: " + pageY + "\n"
               //    +  "Nail X: " + nailX + "\nNail Y: " + nailY + "\n"
               //    + "Image X: " + imageX + "\nImage Y: " + imageY + "\n"
               //    + "Image Index: " + imageIndex + "\n"
               //    + "Board X: " + boardX + "\nBoard Y: " + boardY + "\n"
               //    + "pdfX: " + pdfX + "\npdfY: " + pdfY + "\n"
               //    + "PDF Nail Diam: " + pdf_nail_diam);
            }
         }
      }
   }

   const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
   const element = document.createElement("a");
        element.href = pdfDataUri;
        element.download = "overlay.pdf";

   return element;
}