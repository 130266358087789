import ImageDisplay from "../../common/ImageDisplay";
import { ContentRow } from "../projectProgression/ContentRow";

import frog from "../../../images/frog-orig.webp";
import frognail from "../../../images/frog-nail.webp";
import frognailed from "../../../images/frog-nailed.jpg";



export const Overview = () => {

    return (
        <section className={"mt-6"}>
            <div className={"flex flex-col items-center min-h-max w-full mb-2 justify-center"}>
                <div className={"grid grid-cols-2 gap-x-10 gap-y-2 h-full w-5/6"} >
                    <ImageDisplay image={frog} />
                    <ImageDisplay image={frognailed} />
                    <ContentRow title={"Your image"} text={"Upload any of your favorite photos"} />
                    <ContentRow title={"Your art"} text={"Create some wicked nail art"} />
                </div>
                <section className={"w-1/2 mt-16 mb-8 text-xl text-start text-dark-600 dark:text-light-500"}>
                    Nail Art is a fairly uncommon and relatively difficult form of art that resembles stippling, a technique to create images using a set of dots or circles of the same color. However, instead of painting dots on a canvas, some artists like David Foster use nails. Our goal is to make this form of art much more approachable to aspiring artists of any skill level. We have created this website that will take any image and turn it into a stippled image using an algorithm called dithering. From there, artists can create the physical art piece using one of two methods: a paper overlay or a CNC machine. Our product allows artists to print off the stippled image, hammer a nail into every dot, and then rip away the paper overlay. Alternatively, we are able to translate the stippled image into a G-Code file, where artists can let a CNC Machine drill out pilot holes for each nail and pound them in afterwards.
                </section>
            </div>
        </section>
    );
}