import { ProjectProgressionRow } from "./ProjectProgressionRow";

import morig from "../../../images/marilyn.jpg";
import mdither from "../../../images/marilyn-dither.png";
import mnail from "../../../images/marilyn-nailed.jpg";
import mgcode from "../../../images/marilyn-gcode.png";

export const ProjectProgression = () => {

    return (
        <section className={"flex flex-col h-full w-full"}>
            <ProjectProgressionRow backgroundImage={morig} title={"Upload Your Image"} text={"Upload an image directly from your device to turn into nail art."} />
            <ProjectProgressionRow backgroundImage={mdither} title={"We'll Do the Math"} text={"We'll send your image through our custom algorithm to produce some knarly nail art."} />
            <ProjectProgressionRow backgroundImage={mnail} title={"See the Result"} text={"Visualize what your image looks like as nail art before you commit to it. We'll handle the materials tracking and give you a cost estimate. Credit: David Foster Art https://www.davidfosterart.com/index.html"} />
            <ProjectProgressionRow backgroundImage={mgcode} title={"Create Your Art"} text={"Download the G-Code for a CNC Machine to drill pilot holes for your nails. Alternatively, print off a paper overlay, tape it to your board, pound in the nails, and remove the paper."} />
        </section>
    );
}