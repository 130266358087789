import { Checkbox, IconButton } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { updateNailDensity, updateNailRandomness } from "../../../stores/Settings-Slice";
import { updateEroded } from "../../../stores/Image-Slice";
import { useAppDispatch, useAppSelector } from '../../../stores/Hooks';
import { eroder } from "../../../services/ImageTranslation";

import { InputSection } from "../../common/InputSection";
import { ToolDrawer } from "./ToolDrawer";
import { Slider } from "../../common/Slider";

export const ArtSettings = () => {
  const settings = useAppSelector((s) => s.setting);
  const images = useAppSelector((s) => s.image);
  const dispatch = useAppDispatch();

  // const onRandomnessClick = async () => {
  //   let dithered = images.ditheredImage;

  //   let erodedBlob = await eroder(dithered, settings.nailDensity, settings.nailRandomness);
  //   let erodedImage = URL.createObjectURL(erodedBlob);

  //   dispatch(updateEroded(erodedImage));
  // }

  const randomnessSection = (
    <div>
      {/* <div className={"flex"}>
        <IconButton size={"small"} disabled={images.originalImage === "" || settings.nailDensity === 10} onClick={onRandomnessClick}>
          <AutorenewIcon />
        </IconButton>
      </div> */}
      <Slider value={settings.nailRandomness} min={1} max={10} step={1} disabled={images.originalImage === "" || settings.nailDensity === 10} onChange={(e) => dispatch(updateNailRandomness(+e.target.value))} />
    </div>

  )

  return (
    <ToolDrawer title={"Art Settings"}>
      <InputSection title={"Dot Density"}>
        <Slider value={settings.nailDensity} min={5} max={10} step={1} onChange={(e) => dispatch(updateNailDensity(+e.target.value))} />
      </InputSection>
      <InputSection title={"Randomness"}>
        {randomnessSection}
      </InputSection>
    </ToolDrawer>
  );
}