
interface IProps {
    title: string;
    children: JSX.Element[] | JSX.Element;
}

export const ToolDrawer = ({ title, children }: IProps) => {
    return (
        <div className={"flex flex-col w-[320px] p-4"}>
            <span className={"flex justify-self-start text-2xl tracking-wide text-dark-600 dark:text-light-500"}>{title}</span>
            {children}
        </div>
    );
}