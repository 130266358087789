import { Dispatch, SetStateAction } from "react"
import logo from '../images/MiniLogo.png';

interface NavBarProps {
  workPage: boolean,
  setWorkPage: Dispatch<SetStateAction<boolean>>
}

export default function NavBar({ workPage, setWorkPage }: NavBarProps) {
  return (
    <div id={"navBar"} className={"flex w-screen items-center place-content-between border-b-2 bg-dark-100 text-dark-600 border-b-primary-200 dark:bg-mixed-600 dark:text-light-500 dark:border-b-primary-600"}>
      <div className={"grid grid-cols-2 min-w-max"}>
        <button data-open={!workPage} className={"h-16 min-w-max px-9 text-xl tracking-wider border-0 data-[open=true]:bg-mixed-200 dark:data-[open=true]:bg-mixed-500"} onClick={() => setWorkPage(false)}>Home</button>
        <button data-open={workPage} className={"h-16 min-w-max px-9 text-xl tracking-wider border-0 data-[open=true]:bg-mixed-200 dark:data-[open=true]:bg-mixed-500"} onClick={() => setWorkPage(true)}>Editor</button>
      </div>
      <p className={"min-w-max self-center truncate text-4xl font-bold tracking-widest capitalize text-primary-600"}>The Knights Who Say Nail</p>
      <img src={logo} className={"w-min h-min justify-self-end mr-4"} alt={"The Knights' Logo"} onClick={() => setWorkPage(false)} />
    </div>
  )
}