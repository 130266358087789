import { Dispatch, SetStateAction } from "react";
import Footer from "../footer/Footer";
import { Overview } from "./projectOverview/Overview";
import { ProjectProgression } from "./projectProgression/ProjectProgression";

interface HomePageProps {
    setWorkPage: Dispatch<SetStateAction<boolean>>
}

export const HomePage = ({ setWorkPage }: HomePageProps) => {
    return (
        <div className={"flex flex-col"}>
            <Overview />
            <button className={"self-center box-content min-h-max py-2 w-1/6 mb-16 rounded-md bg-red-800 border-2 border-transparent hover:border-red-400"} onClick={() => setWorkPage(true)} >
                <span className={"text-lg font-bold tracking-wider text-light-500"}>Get Started</span>
            </button>
            <ProjectProgression />
            <footer>
                <Footer />
            </footer>
        </div>
    );
}