import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ACCEPTED_FILE_TYPES = [".png", ".jpg"];

interface State {
    originalImage: string;
    sizedImage: string;
    ditheredImage: string;
    erodedImage: string;
    hasImage: boolean;
}

const initialState: State = {
    originalImage: "",
    sizedImage: "",
    ditheredImage: "",
    erodedImage: "",
    hasImage: false,
};

const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        //Update the Original Image
        updateOriginal(state, action: PayloadAction<string>) {
            state.originalImage = action.payload;
            state.hasImage = true;
        },
        //Update the Sized/Scaled Image
        updateSized(state, action: PayloadAction<string>) {
            state.sizedImage = action.payload;
        },
        //Update the Dithered Image
        updateDithered(state, action: PayloadAction<string>) {
            state.ditheredImage = action.payload;
        },
        //Update the Eroded Image
        updateEroded(state, action: PayloadAction<string>) {
            state.erodedImage = action.payload;
        },
        //Resets all images
        clearImages(state) {
            state.erodedImage = "";
            state.ditheredImage = "";
            state.sizedImage = "";
            state.originalImage = "";
            state.hasImage = false;
        }
    }
})

export const { updateOriginal, updateSized, updateDithered, updateEroded, clearImages } = imageSlice.actions;
export default imageSlice.reducer;