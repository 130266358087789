import { useAppDispatch, useAppSelector } from '../../../stores/Hooks';
import { ToolDrawer } from "./ToolDrawer";
import { UnitTypeToggle } from './UnitTypeToggle';
import { InputSection } from "../../common/InputSection";
import { ConstrainedNumberInput } from "../../common/ConstrainedNumberInput";
import { updateDrillDepth, updateOffset, switchType } from "../../../stores/Settings-Slice";

export const GCodeSettings = () => {
  const settings = useAppSelector((s) => s.setting);
  const dispatch = useAppDispatch();

  // GCode Settings Inputs + Handlers
  const depthInput = (
    <div className={"flex xl:w-96"}>
      <ConstrainedNumberInput label={settings.unitType} minimum={settings.depthOffConstraints.min} maximum={settings.boardDepth - (settings.unitType === "in" ? 1 : 25.4) * 0.5} value={settings.drillDepth} onBlurHandler={(e) => dispatch(updateDrillDepth(+e.target.value))} />
    </div>
  );

  // GCode Settings Inputs + Handlers
  const offsetInput = (
    <div className={"flex xl:w-96"}>
      <ConstrainedNumberInput label={settings.unitType} minimum={settings.depthOffConstraints.min} maximum={settings.depthOffConstraints.max} value={settings.offset} onBlurHandler={(e) => dispatch(updateOffset(+e.target.value))} />
    </div>
  );

  // Gcode Type Settings
  const gcodeInput = (
    <div className={"flex"}>
      <button data-active={settings.type === "Simple"}
        className={"h-10 w-28 self-end px-4 rounded-l-md bg-light-100 data-[active=true]:bg-secondary-600 hover:data-[active=false]:bg-secondary-300"}
        onClick={() => dispatch(switchType())}>
        <span className={"font-bold tracking-wider text-light-500"}>Simple</span>
      </button>
      <button data-active={settings.type === "Peck Cycles"}
        className={"h-10 w-32 self-end px-4 rounded-r-md bg-light-100 data-[active=true]:bg-secondary-600 hover:data-[active=false]:bg-secondary-300"}
        onClick={() => dispatch(switchType())}>
        <span className={"font-bold tracking-widest text-light-500"}>Industrial</span>
      </button>
    </div>
  )

  return (
    <ToolDrawer title={"CNC Settings"}>
      <InputSection title={"Units"}><UnitTypeToggle /></InputSection>
      <InputSection title={"GCode Type"}>{gcodeInput}</InputSection>
      <InputSection title={`Drill Depth`}>{depthInput}</InputSection>
      <InputSection title={`Side Margins`}>{offsetInput}</InputSection>
    </ToolDrawer>
  );
}