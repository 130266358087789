interface IProps {
    image: string;
    textOverlay?: string;
}

export const ImageRow = ({ image, textOverlay }: IProps) => {

    const overlay = (
        <div className={"relative h-6 max-w-min bg-red-900"}>
            <div className={"absolute"}>{textOverlay}</div>
        </div>
    );

    return (
        <div className={"h-full bg-fixed bg-contain bg-no-repeat bg-top"} style={{ backgroundImage: `url(${image})` }}>
            {textOverlay && overlay}
        </div>
    );
}